import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import ArticleEmbed from "../components/blocks/articleEmbed"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactFrom"
import { getPageSlug } from "../utils/pageSlug"
import Categories from "../components/blocks/categoryBlock"
import { useFavicon } from "../hooks/useFavicon"
import TitleAndDescription from "../components/blocks/titleAndDescription"
import Breadcrumbs from "../components/breadcrumbs"
import CustomPinnedVideo from "../components/blocks/customPinnedVideo"
import PastEvents from "../components/blocks/pastEvents"
import EventHero from "../components/blocks/eventHero"
import CtaBanner from "../components/blocks/ctaBanner"
import SponsorCollection from "../components/blocks/sponsorCollection"
const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({ data: { page, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout
      pageSlug={getPageSlug(page)}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ pb: [0, 0] }}>
        <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
          <Breadcrumbs page={page} />
        </Box>
      </Container>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "sponsor_collection" && (
            <SponsorCollection title={block.title} sponsors={block.sponsors} />
          )}
          {block.model.apiKey === "title_and_body" && (
            <Container>
              <TitleAndBody
                title={block.content.title}
                body={block.content.body}
              />
            </Container>
          )}
          {block.model.apiKey === "title_and_description" && (
            <TitleAndDescription block={block} />
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.title}
              subtitle={block.subtitle}
              body={block.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "document_collection" && (
            <DocumentCollection
              title={block.title}
              documents={block.documents}
              showPublicationDate={block.showPublicationDate}
            />
          )}
          {block.model.apiKey === "image" && <Image image={block.image} />}
          {block.model.apiKey === "locations_map" && (
            <LocationsMap locations={block.locations} />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
          {block.model.apiKey === "embed" && (
            <ArticleEmbed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "form_embed" && (
            <ContactForm pageTitle={page.title} title={block.title} />
          )}
          {block.model.apiKey === "image_gallery" && (
            <Container>
              <ImageGallery images={block.images} />
            </Container>
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
          {block.model.apiKey === "custom_pinned_video" && (
            <CustomPinnedVideo block={block} />
          )}
          {block.model.apiKey === "pastevent" && (
            <PastEvents events={block.events} />
          )}
          {block.model.apiKey === "eventhero" && <EventHero block={block} />}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
              rightAligned={block.rightAligned}
            />
          )}
          {block.model.apiKey === "category" && (
            <Categories
              page={page}
              title={block.title}
              description={block.description}
            />
          )}
          {block.model.apiKey === "cta_banner" && (
            <CtaBanner
              headline={block.headline}
              subheading={block.subheading}
              backgroundImage={block.backgroundImage}
              buttonColor={block.buttonColor.hex}
              link={block.link}
            />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsSponsorCollection {
          id
          title
          sponsors {
            name
            description
            url
            image {
              gatsbyImageData(width: 1480, placeholder: BLURRED)
            }
            playlist {
              originalId
              title
              videos {
                ...AllVideoSlugLocales
                slug
                title
                description
                videoId
                videoLink
                locale
                duration
                meta {
                  firstPublishedAt
                }
                thumbnail {
                  desktop: gatsbyImageData(placeholder: BLURRED, height: 1000)
                  mobile: gatsbyImageData(
                    placeholder: BLURRED
                    imgixParams: { fit: "crop", ar: "16:10", h: "800" }
                  )
                  colors {
                    hex
                  }
                }
                speaker {
                  id
                  name
                  jobTitle
                  company
                }
                category {
                  id
                  title
                  locale
                  slug
                  model {
                    apiKey
                  }
                  channel {
                    pageLink {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCtaBanner {
          headline
          subheading
          buttonColor {
            hex
          }
          backgroundImage {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          model {
            apiKey
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              forceUrl
              link {
                ... on DatoCmsEvent {
                  id
                  slug
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsHomePage {
                  locale
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsBlogPage {
                  ...BlogDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                ... on DatoCmsArticleCategory {
                  ...ArticleCategoryDetails
                  ...ArticleCategoryAllSlugLocales
                }
                ... on DatoCmsVideoCategory {
                  ...VideoCategoryPageDetails
                }
              }
            }
          }
        }

        ... on DatoCmsEventhero {
          id
          title
          model {
            apiKey
          }
          event {
            slug
            location
            title
            date
            heroImage {
              url
            }
            primaryColor {
              hex
            }
          }
        }
        ... on DatoCmsPastevent {
          id
          model {
            apiKey
          }
          events {
            primarycolor {
              hex
            }
            image {
              url
            }
            embed
            date
            location
            title
            language
            channel {
              pageLink {
                title
              }
            }
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsFormEmbed {
          title
          code
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          rightAligned
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            id
            name
            addressCountry
            addressRegion
            addressLocality
            email
            faxNumber
            coordinates {
              latitude
              longitude
            }
            postalCode
            streetAddress
            telephone
            locale
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          documents {
            title
            subtitle
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCustomPinnedVideo {
          description
          image {
            gatsbyImageData(placeholder: BLURRED, height: 1000)
          }
          video
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndDescription {
          id
          title
          description
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              forceUrl
              link {
                ... on DatoCmsEvent {
                  id
                  slug
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsHomePage {
                  locale
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsBlogPage {
                  ...BlogDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                ... on DatoCmsArticleCategory {
                  ...ArticleCategoryDetails
                  ...ArticleCategoryAllSlugLocales
                }
                ... on DatoCmsVideoCategory {
                  ...VideoCategoryPageDetails
                }
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          forceUrl
          link {
            ... on DatoCmsEvent {
              id
              slug
              model {
                apiKey
              }
            }
            ... on DatoCmsHomePage {
              locale
              model {
                apiKey
              }
            }
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
            ... on DatoCmsVideoCategory {
              ...VideoCategoryPageDetails
            }
          }
        }
      }
      value
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    treeChildren {
      slug
      root
      treeChildren {
        slug
        root
      }
    }
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(width: 1920, placeholder: BLURRED)
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
